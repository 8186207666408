import React, { Fragment, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Route, HashRouter } from "react-router-dom"
import LandPage from './component/land_page/LandPage';
import Login from './component/login/Login';
import Nav from './component/nav/Nav';
import Papop from './component/papop/Papop';
import Profile from './component/profile/Profile';
import Cot from './component/table/cot/Cot';
import Table from './component/table/Table';
import { Context } from './container/Context';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import List from './component/list/List';
const App = (prop) => {
  const deactive = ['#/login','#/profile']
  const context = useContext(Context)
  return (
    <Fragment>
      <NotificationContainer/>
      {deactive.includes(prop.location.hash) ? null : <Nav />}
      <HashRouter>
        <Route exact path='/' component={LandPage}></Route>
        <Route path='/login' component={Login} />
        <Route path='/game' component={Papop} />
        <Route path='/data' component={Table} />
        <Route path='/list' component={List} />
        <Route path='/profile' component={Profile} />
      </HashRouter>
    </Fragment>

  );
}

export default withRouter(App);