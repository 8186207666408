import React, { Fragment, useEffect, useContext,useRef } from 'react';
import { Context } from '../../../container/Context';


const Sign = ({but}) => {
    const context = useContext(Context)
    const inputElement = useRef();
    useEffect(()=>{
        inputElement.current.focus();
    },[])
    return (
        <Fragment>
            <div className='sign'>
                <h3>سلام !</h3>
                <div><h2>شماره موبایل</h2></div>
                <div>
                    <input type="number" id='user'
                        autoComplete='off'
                        value={context.mobile}
                        onChange={(e) => context.setmobail(e.target.value.slice(0, 11))}
                        placeholder='شماره موبایل ... '
                        onKeyDown={but}
                        ref={inputElement}
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default Sign;