export const redirect = (path) => {
    window.location.hash = path
}

// export const server = "https://localhost:5151"
export const server = "https://serverhookm.devdailychallenge.com"

export const hamal_codi = (team, list, inp) => {
    let point = 0
    let data = {}
    let hambal={}
    let charkh_dasti={}
    if (team[0].value === "") {
        list[1].arr.map(e => {
            data[e] = (point) * 0
            hambal[e]=point + 0
            charkh_dasti[e]=point + 0
        })
        list[0].arr.map(e => {
            data[e] = (point) * 0
            hambal[e]=point + 0
            charkh_dasti[e]=point + 0
        })
    }
    else if (team[0].value === "Team 1") {
        list[1].arr.map(e => {
            data[e] = (point + 1) * inp
            hambal[e]=(point + 1) * inp
            charkh_dasti[e]=(point + 1) * 0
        })
        list[0].arr.map(e => {
            data[e] = (point - 1) * inp
            hambal[e]=(point + 1) * 0
            charkh_dasti[e]=(point + 1) * inp
        })
    }
    else {
        list[0].arr.map(e => {
            data[e] = (point + 1) * inp
            hambal[e]=(point + 1) * inp
            charkh_dasti[e]=(point + 1) * 0
        })
        list[1].arr.map(e => {
            data[e] = (point - 1) * inp
            hambal[e]=(point + 1) * 0
            charkh_dasti[e]=(point + 1) * inp
        })
    }
    return {data,hambal,charkh_dasti}
}
export const hakem_codi = (team, list,king) => {
    let point = 0
    let data = {}
    let cot={}
    let yar_cot={}
    let king_slayer={}
    if (team[1].value === "") {
        list[1].arr.map(e => {
            data[e] = (point*0)
            king_slayer[e] =point+0
            cot[e] = point+0
            yar_cot[e] = point+0
        })
        list[0].arr.map(e => {
            data[e] = (point*0) 
            king_slayer[e] =point+0
            cot[e] = point+0
            yar_cot[e] = point+0
        })
    }
    else if (team[1].value === "Team 1") {
        list[1].arr.map(e => {
            data[e] = (point + 3) 
            king_slayer[e] =point+1
            cot[e] = point+0
            yar_cot[e] = point+0
        })
        const hakem=list[0].arr.filter(e=>e===king)
        data[hakem] = (point - 5) 
        cot[hakem] = point+1
        king_slayer[hakem] =point+0
        yar_cot[hakem] = point+0
        const yar=list[0].arr.filter(e=>e!==king)
        data[yar] = (point - 2) 
        king_slayer[yar] =point+0
        cot[yar] = point+0
        yar_cot[yar] = point+1

    }
    else {
        list[0].arr.map(e => {
            data[e] = (point + 3) 
            king_slayer[e] =point+1
            cot[e] = point+0
            yar_cot[e] = point+0
        })
        const hakem=list[1].arr.filter(e=>e===king)
        data[hakem] = (point - 5) 
        cot[hakem] = point+1
        king_slayer[hakem] =point+0
        yar_cot[hakem] = point+0
        const yar=list[1].arr.filter(e=>e!==king)
        data[yar] = (point - 2) 
        king_slayer[yar] =point+0
        cot[yar] = point+0
        yar_cot[yar] = point+1
    }
    return {data,king_slayer,cot,yar_cot}
}

export const game_data=(teams,inp)=>{
    let win=0
    let data={}
    if (inp[0].checked === true) {
        teams[0].arr.map(e => {
            data[e] = win+1
        })
        teams[1].arr.map(e => {
            data[e] = win+0
        })
        
    }
    else{
        teams[0].arr.map(e => {
            data[e] = win+0
        })
        teams[1].arr.map(e => {
            data[e] = win+1
        })
    }
    return data
}