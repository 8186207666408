import React, { Fragment,useEffect } from 'react';



const Cot = ({ users }) => {
    console.log();
    useEffect(()=>{

    },[users])
    return (
        <Fragment>
            <div>
                <div><h1>حاکم کدی ها</h1></div>
                <div className='all_table'>
                    <div className='table'>
                        <h2>نفر</h2>
                        <h2>نام</h2>
                        <h2>تعداد</h2>
                    </div>
                    <div>
                    {users.sort((a,b)=>b.hakem_code-a.hakem_code)?.map((each,i)=>(
                            <div className={i%2=== 0 ?'table_user':'table_user bg_fard'}>
                                <div><h2>{i+1}</h2></div>
                                <div><h2>{each.fullName}</h2></div>
                                <div><h2>{each.hakem_code}</h2></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Cot;