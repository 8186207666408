import axios from 'axios';
import React,{ Fragment,useEffect,useState,useContext}  from 'react';
import { Context } from '../../container/Context';
import { server } from '../../container/Helper';
import Cot from './cot/Cot';
import Charkhi from './H-charkhi/Charkji';
import Hamal from './hamal/Hamal';
import Jaqi from './jaq_yar/Jaqi';
import King from './king/King';
import Score from './score/Score';

import './table.css'

const Table = () => {
    const context = useContext(Context)
    const [data,setdata]=useState([])
    const all_user = async () => {
        const data = await axios.get(`${server}/get/all_user`)
        setdata(data.data.data);
    }
    useEffect(()=>{
        all_user()
    },[])

    const comps=[
        <Cot users={data}/>,
        <King users={data}/>,
        <Jaqi users={data}/>,
        <Hamal users={data}/>,
        <Charkhi users={data}/>,
        <Score users={data}/>
    ]

    return ( 
        <Fragment>
            <div className='list_table'>
                   {comps[context.sel]}
            </div>
        </Fragment>
     );
}
 
export default Table;