import React, { Fragment, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import './land.css'
import { redirect } from '../../container/Helper';

const LandPage = () => {
    const start_game = () => {
        const token = localStorage.getItem('token')
        if (!token) {
            NotificationManager.success('برای ثبت بازی وارد اکانت شوید')
            redirect('/login')
        }
        else {
            redirect('/game')
        }
    }

    return (
        <Fragment>
            <div className='land'>

                <div className='land_each'>
                    <div>
                        <h1>برای شروع بازی جدید اینجا کلیک کنید</h1>
                    </div>
                    <div className='land_but'><button onClick={start_game}>بازی جدید</button></div>
                </div>
                <div className='list_but'>
                    {localStorage.getItem('token') ? 
                    JSON.parse(localStorage.getItem('token')).access !== 1  ?
                    <div><button onClick={()=>{redirect('/list')}}>لیست</button></div>
                    :
                    null
                    :
                    null
                    }
                </div>
            </div>
        </Fragment >
    );
}

export default LandPage;