import React, { createContext, useState } from 'react'
export const Context = createContext();

const ContextProvider = (props) => {

    const [mobile, setmobail] = useState()
    const [fullName, setFullName] = useState()
    const [pass, setPass] = useState()
    const [passT,setPassT]= useState()
    const [sel,setSel]=useState(0)
    return (
        <Context.Provider value={{
            mobile,setmobail,fullName,setFullName,pass,
            setPass,passT,setPassT,sel,setSel
        }}>
            {props.children}
        </Context.Provider>
    )
}


export default ContextProvider