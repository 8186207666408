import React, { Fragment, useContext ,useRef,useEffect} from 'react';
import { Context } from '../../../container/Context';


const Register = ({but}) => {
    const context = useContext(Context)
    const inputElement = useRef();
    useEffect(()=>{
        inputElement.current.focus();
    },[])
    return (
        <Fragment>
            <div className='regist'>
                <p>لطفا نام و نام خانوادگی را فارسی وارد کنید</p>
                <div>
                    <div><h2>نام و نام خانوادگی</h2></div>
                    <div><input type="text" id='fullName'
                        autoComplete='off'
                        value={context.fullName}
                        ref={inputElement}
                        onChange={(e) => context.setFullName(e.target.value)}
                    /></div>
                </div>
                <div>
                    <div><h2>پسورد</h2></div>
                    <div><input type="password" id='pass'
                        value={context.pass}
                        onChange={(e) => context.setPass(e.target.value)}
                    /></div>
                </div>
                <div>
                    <div><h2>تکرار پسورد</h2></div>
                    <div><input type="password" id='passT'
                        value={context.passT}
                        onKeyDown={but}
                        onChange={(e) => context.setPassT(e.target.value)} /></div>
                </div>
            </div>
        </Fragment>
    );
}

export default Register;