import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react';
import { game_data, hakem_codi, hamal_codi, redirect, server } from '../../container/Helper';
import { NotificationManager} from 'react-notifications';

import './pop.css'

const Papop = () => {
    const [user, setUser] = useState([])
    const [searchList, setSearchList] = useState([])
    const [sel, setsel] = useState('')
    const [search, setSearch] = useState([])
    const [num, setNum] = useState('')
    let [teams, setTeams] = useState([{ name: `Team 1`, arr: [] }, { name: `Team 2`, arr: [] }])
    const all_user = async () => {
        const data = await axios.get(`${server}/get/all_user`)
        setUser(data.data.data);
    }
    const handle_team = (team, index) => {
        const inps = document.querySelectorAll('#teams')
        if (inps[index].value !== '') {
            if (team.arr.length < 2) {
                let new_team = { ...team }
                new_team.arr.push(inps[index].value)
                let up = [...teams]
                up[index] = new_team
                setTeams(up)
            }
            else {
                console.log('fild');
            }
        }
        else {
            console.log('fiiild');
        }
    }
    const serach_inp = () => {
        if ((search.length < 1) || (!search)) return
        let flist = teams[0].arr.concat(teams[1].arr)
        let filter_list = [...user].filter(e => !flist.includes(e.fullName))
        const filter_inp = [...filter_list].filter(each => (each.fullName.indexOf(search) > -1))
        setSearchList(filter_inp)
    }
    const handle_search_inp = (name, i) => {
        const inps = document.querySelectorAll('#teams')
        const list = document.querySelectorAll('.search_list')
        inps[i].value = name.fullName
        list[i].style.display = 'none'
    }

    useEffect(()=>{
        const token=localStorage.getItem('token')
        if (!token) {
         NotificationManager.success('برای ثبت بازی وارد اکانت شوید') 
         redirect('/login')
        }
        else{
         redirect('/game')
        }
    },[])

    useEffect(() => {
        all_user()
        serach_inp()
    }, [search, teams])

    const open_search = (i) => {
        const list = document.querySelectorAll('.search_list')
        list[i].style.display = 'block'
    }

    const close_search = (e) => {
        // console.log(i);
        const list = document.querySelectorAll('.search_list')
        const isoutinner = e.closest('.search_list,#teams')
        // console.log(isoutinner);
        if (!isoutinner) {
            list.forEach(each => {
                each.style.display = 'none'
            })
        }
    }

    const delet = (x, i, each) => {
        let new_team = { ...each }.arr
        const filteredArray = new_team.filter(item => item !== x);
        new_team = filteredArray
        let up = [...teams]
        up[i].arr = new_team
        setTeams(up)
    }
    const send_data = async () => {
        let check_inp = check_data()
        const token =localStorage.getItem('token')
        if (!check_inp) {
            let res = get_data()
            const { data } = await axios.post(`${server}/calc/game`, { ress:res,token })
            NotificationManager.success(`${data.msg}`)
            redirect('#/')
        }
    }
    const check_data = () => {
        const ress = document.querySelector('#ress')
        let check = false
        if (teams[0].arr.length < 2) {
            check = true
            NotificationManager.error('تیم اول را کامل کنید')
        }
        else if (teams[1].arr.length < 2) {
            check = true
            NotificationManager.error('تیم دوم را کامل کنید')
        }
        else if (ress) {
            if ((ress.value == 0) || (ress.value === '')) {
                NotificationManager.error('تعداد حمال ها را ثبت کنید')
                check = true
            }
        }
        return check
    }
    const get_data = () => {
        let is_hokem;
        let is_hamal;
        const raido_inp = document.querySelectorAll('.redio')
        const selects = document.querySelectorAll('.slect')
        const ress = document.querySelector('#ress')
        num === '' ? is_hamal = '' : is_hamal = ress.value
        const king = document.querySelector('.king')
        sel === '' ? is_hokem = '' : is_hokem = king.value
        const hamal = hamal_codi(selects, teams, is_hamal)
        const hakem = hakem_codi(selects, teams, is_hokem)
        const win_lose = game_data(teams, raido_inp)
        let score = teams.map(ss => {
            return ss.arr.map(s => {
                return {
                    name: s,
                    score: hamal.data[s] + hakem.data[s], win: win_lose[s], hamal: hamal.hambal[s],
                    king: hakem.king_slayer[s], cot: hakem.cot[s], jaqyar: hakem.yar_cot[s], hamalCharkhi: hamal.charkh_dasti[s]

                }
            })
        })
        score = score[0].concat(score[1])
        return score
    }

    const handle_div = () => {
        switch (sel) {
            case (''): return <h2>حاکم کدی نداشتیم</h2>
            case ('Team 1'): return <div className='flex_inner'>
                <div><p>چه کسی :</p></div>
                <select name="" id="" className='king'>
                <option value={teams[0].arr[0]}>{teams[0].arr[0]}</option>
                <option value={teams[0].arr[1]}>{teams[0].arr[1]}</option>
            </select>
            </div>
            case ('Team 2'): return <div className='flex_inner'>
                <div><p>چه کسی :</p></div>
                <select name="" id="" className='king'>
                <option value={teams[1].arr[0]}>{teams[1].arr[0]}</option>
                <option value={teams[1].arr[1]}>{teams[1].arr[1]}</option>
            </select></div>
            default: return "hi"
        }
    }

    useEffect(() => {
        const inps = document.querySelectorAll('#teams')
        inps.forEach(each => {
            each.value = ''
        })
    }, [teams])
    return (
        <Fragment>
            <div className='outer_pop' onClick={(e) => close_search(e.target)}>
                <div className='inner_pop'>
                    {teams.map((each, i) => (
                        <div>
                            <div><h2>{each.name}</h2></div>
                            <div className={i === 0 ? 'team1' : 'team2'}>
                                <div className='pop_flex'>
                                    <div><input type="text" id='teams'
                                        autoComplete='off'
                                        onChange={(e) => setSearch(e.target.value)}
                                        onFocus={() => open_search(i)}
                                    /></div>
                                    <div className='search_list'>
                                        {searchList?.map(each => (
                                            <div><p onClick={() => handle_search_inp(each, i)}>{each.fullName}</p></div>
                                        ))}
                                    </div>
                                    <div className='serach_but'><button onClick={() => handle_team(each, i)}><i className='fas fa-check'></i></button></div>
                                </div>

                                <div>
                                    {each.arr.map((e) => (
                                        <div className='each_inner'>
                                            <div><h2>{e}</h2></div>
                                            <div><button onClick={() => delet(e, i, each)}><i className='fas fa-trash-alt'></i></button></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='inner_data'>
                    <div>
                        <div><h2>حمال کدی شده ها :</h2></div>
                        <div className='inner_hamal'>
                            <div>
                                <select name="" className="slect" onChange={(e) => setNum(e.target.value)}>
                                    <option value="">...</option>
                                    {teams.map(each => (
                                        <option value={each.name}>{each.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                {num !== '' ?
                                    <div className='flex_inner'>
                                        <p>چند بار :</p>
                                        <input type="number" id='ress' min={1} max={9} />
                                    </div> :
                                    <h2>حمال چرخی نداشتیم</h2>
                                    }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div><h2>حاکم کدی شده ها :</h2></div>
                        <div className='inner_hakem'>
                            <div>
                            <select name="" className="slect" onChange={(e) => setsel(e.target.value)}>
                                <option value="">...</option>
                                {teams.map(each => (
                                    <option value={each.name}>{each.name}</option>
                                ))}
                            </select>
                            </div>
                            <div>
                                {handle_div()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex_inner win_game'>
                    <div><h2>برنده بازی :</h2></div>
                    <div className='flex_inner'>
                        <div>
                            <label>
                                <span class="checkmark" >{teams[0].name}</span>
                                <input name="path" value='news' type="radio" defaultChecked="checked" className="redio" />
                            </label>
                        </div>
                        <div>
                            <label>
                                <span class="checkmark">{teams[1].name}</span>
                                <input name="path" value='edus' type="radio" className="redio" />
                            </label>
                        </div>
                    </div>
                </div>

                <div className='inner_but'><button onClick={send_data}>اتمام بازی</button></div>
            </div>
        </Fragment>
    );
}

export default Papop;