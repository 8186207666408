import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { redirect, server } from '../../container/Helper';

import './pro.css'

const Profile = () => {
    const [user, setUser] = useState({})
    const get_data_user = async () => {
        const { id } = JSON.parse(localStorage.getItem('token'))
        const { data } = await axios.post(`${server}/get/data_user`, { id: id })
        setUser(data.data);
    }

    useEffect(() => {
        get_data_user()
    }, [])

    const remove_token = () => {
        localStorage.removeItem('token')
        redirect('/')
    }
    return (
        <Fragment>
            <div className='profile'>
                <div className='pro_name'>
                    <div><h1>{user?.fullName}</h1></div>
                    <div>
                        <div><h1>امتیاز : {user?.score}</h1></div>
                        <div><h1>  درصد برد : %{Math.round((user?.win / user?.game) * 100)}</h1></div>

                    </div>
                </div>
                <div>
                    <div><h2>اطلاعات بازی :</h2></div>
                    <div className='game_pro'>
                        <div><h2><span className='red'>تعداد بازی :</span> {user?.game}</h2></div>
                        <div><h2> <span className='red'> برد :</span>  {user?.win}</h2></div>
                        <div><h2> <span className='red'> باخت :</span>  {user?.game - user?.win}</h2></div>
                    </div>
                </div>
                <div>
                    <div><h2>اطلاعات حاکم :</h2></div>
                    <div className='game_pro'>
                        <div><h2><span className='red'>حاکم کدی :</span> {user?.hakem_code}</h2></div>
                        <div><h2> <span className='red'> جق یار :</span>  {user?.jaq_yar}</h2></div>
                        <div><h2> <span className='red'>کینگ اسلیر :</span>  {user?.king_slayer}</h2></div>

                    </div>
                </div>
                <div>
                    <div><h2>اطلاعات حمال :</h2></div>
                    <div className='game_pro'>
                        <div><h2> <span className='red'> حمال چرخی :</span>  {user?.hamal_charkhi}</h2></div>
                        <div><h2> <span className='red'>حمال کدی  :</span>  {user?.hamal_code}</h2></div>

                    </div>
                </div>
                <div className='pro_but'>
                    <button onClick={() => redirect('/')}>صفحه اصلی</button>
                </div>
                <div onClick={remove_token} className='exit'>خروج از اکانت ...</div>
            </div>
        </Fragment>
    );
}

export default Profile;