import React, { Fragment, useState, useContext } from 'react';
import './login.css'
import Pass from './pass/Pass';
import Register from './register/Register';
import Sign from './sign/Sign';
import { Context } from '../../container/Context';
import axios from 'axios';
import { redirect, server } from '../../container/Helper';
import { NotificationManager } from 'react-notifications';

const Login = () => {
    const context = useContext(Context)
    const [select, setSelect] = useState(0)

    const handle_sgin = () => {
        switch (select) {
            case (0): return handlePhone()
            case (1): return handlePass()
            case (2): return handleRegister()
            default: return 'hi'
        }
    }

    const handle_Div = () => {
        switch (select) {
            case (0): return <Sign but={handleKeyDown} />
            case (1): return <Pass but={handleKeyDown} />
            case (2): return <Register but={handleKeyDown} />
            default: return 'hi'
        }
    }

    const handlePhone = async () => {
        let phone = context.mobile
        if ((phone) && (phone.length > 10)) {
            const { data } = await axios.post(`${server}/regist/pass`, { phone })
            if (data.status === false) {
                NotificationManager.error(`${data.msg}`)
            }
            else {
                data.data === false ? setSelect(2) : setSelect(1)
                if (data.data === false) {
                    NotificationManager.success('مشخصات خود را وارد کنید')
                }
                else {
                    NotificationManager.success('پسورد خود را وارد کنید')
                }
            }
        }
        else {
            NotificationManager.error('کسخل شمارتو درست وارد کن')
        }
    }

    const handleRegister = async () => {
        const check_inp = check_pass()
        let info = {
            phone: context.mobile,
            fullName: context.fullName,
            pass: context.pass,
        }
        if (check_inp === true) {
            const { data } = await axios.post(`${server}/regist/add`, info)
            console.log(data);
            if (data.status === true) {
                let test = JSON.stringify(data.data)
                console.log(test);
                localStorage.setItem("token", test)
                redirect('/')
                NotificationManager.success(`${data.msg}`)
            }
            else {
                NotificationManager.error(`${data.msg}`)
            }
        }
        else {
            NotificationManager.error('فیلد ها را پر کنید')
        }
    }
    const check_pass = () => {
        let ch_pass = false
        if (context.pass !== context.passT) {
            ch_pass = false
            NotificationManager.error('پسورد را صحیح وارد کنید')
            context.setPassT('')
        }
        else if (!context.fullName || !context.pass) {
            ch_pass = false
        }
        else {
            ch_pass = true
        }


        return ch_pass
    }


    const handlePass = async () => {
        let info = {
            phone: context.mobile,
            pass: context.pass,
        }
        if ((!info.pass) && (info.pass.length < 1)) {
            NotificationManager.error('پسورد را وارد کنید')
        }
        else {
            const { data } = await axios.post(`${server}/regist/sign`, info)
            if (data.status === true) {
                let test = JSON.stringify(data.data)
                localStorage.setItem("token", test)
                NotificationManager.success(`${data.msg}`)
                redirect('/')
            }
            else {
                NotificationManager.error(`${data.msg}`)
            }
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handle_sgin()
        }
    };

    return (
        <Fragment>
            <div className='bg_login'>
                <div className='login_r'>
                    <div className='logo_login'>
                        <img src="../img/chip.png" alt="" onClick={() => redirect('#/')} />
                    </div>
                    <div>
                        {handle_Div()}
                    </div>
                    <div><button onClick={handle_sgin}>ثبت</button></div>
                </div>
            </div>
        </Fragment>
    );
}

export default Login;