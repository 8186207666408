import axios from 'axios';
import React,{ Fragment ,useEffect,useState} from 'react';
import { redirect, server } from '../../container/Helper';
    import './list.css'

const List = () => {
    const [list,setList]=useState({})
    const [dat,setdat]=useState([])
    console.log(list.data);
    const all_list=async()=>{
        const {data}=await axios.get(`${server}/get/all_list`)
        setList(data.data[0]);
        setdat(data.data[0].data)
    }
    useEffect(()=>{
        all_list()
        if (JSON.parse(localStorage.getItem('token')).access === 1) {
            redirect('#/')
        }
    },[])
    return ( 
        <Fragment>
          <div>
          <div className='list'>
                <div className='sabt_date'>
                    <div><h2>{list.fullName}</h2></div>
                    <div>date</div>
                </div>
                <div>
                    {dat.map(each=>(
                        <div className='data_list'>
                            <div>{each.name}</div>
                            <div>cot :{each.cot}</div>
                            <div>jyar :{each.jaqyar}</div>
                            <div>k S:{each.king}</div>
                            <div>hmal:{each.hamal}</div>
                            <div>chrkh:{each.hamalCharkhi}</div>
                            <div>win:{each.win}</div>
                            <div>score:{each.score}</div>
                        </div>
                    ))}
                </div>
            </div>
          </div>
        </Fragment>
     );
}
 
export default List;