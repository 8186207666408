import React,{ Fragment,useContext } from 'react';
import { Context } from '../../container/Context';
import { redirect } from '../../container/Helper';
import './nav.css'

const Nav = () => {
    let link=[
        {name:'حاکم کدی ها',path:'/data'},
        {name:'کینگ اسلیر',path:'/data'},
        {name:'جق یار',path:'/data'},
        {name:'حمال کدی ها',path:'/data'},
        {name:'حمال چرخی ها',path:'/data'},
        {name:'امتیازها',path:'/data'},
    ]

    const context = useContext(Context)

    const handle_link=(e,i)=>{
        context.setSel(i)
        redirect(e.path)
    }


    return ( 
        <Fragment>
            <div className='nav'>
                <div className='logo'>
                    <img src="../img/chip.png" alt="" onClick={()=>redirect('#/')}/>
                </div>
                <div>
                    {!localStorage.getItem('token')?
                    <button onClick={()=>redirect('/login')}>ورود | ثبت نام</button>
                    :
                    <div className='flex_inner'>
                        <div><h2>{JSON.parse(localStorage.getItem('token')).fullName}</h2></div>
                        <div><i className='fas fa-user' onClick={()=>redirect('/profile')}></i></div>
                    </div>
                    }
                </div>
            </div>
            <div>
            <div className='link'>
                    {link.map((each,i)=>(
                        <div className='each_link'>
                            <h2 onClick={()=>handle_link(each,i)}>{each.name}</h2>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
     );
}
 
export default Nav;