import React, { Fragment ,useContext,useEffect,useRef} from 'react';
import { Context } from '../../../container/Context';


const Pass = ({but}) => {

    const context = useContext(Context)
    const inputElement = useRef();
    useEffect(()=>{
        inputElement.current.focus();
    },[])
    return (
        <Fragment>
            <div className='sign'>
                <div><h2>پسورد</h2></div>
                <div><input type="password" id='user' 
                value={context.pass}
                onChange={(e) => context.setPass(e.target.value)}
                onKeyDown={but}
                ref={inputElement}
                /></div>
            </div>
        </Fragment>
    );
}

export default Pass;