import React,{Fragment,useEffect} from 'react';


const Charkhi = ({users}) => {
    useEffect(()=>{

    },[users])
    return ( 
        <Fragment>
        <div>
            <div><h1>حمال چرخی ها</h1></div>
            <div className='all_table'>
                <div className='table'>
                    <h2>نفر</h2>
                    <h2>نام</h2>
                    <h2>تعداد</h2>
                </div>
                <div>
                {users.sort((a,b)=>b.hamal_charkhi-a.hamal_charkhi)?.map((each,i)=>(
                        <div className={i%2=== 0 ?'table_user':'table_user bg_fard'}>
                            <div><h2>{i+1}</h2></div>
                            <div><h2>{each.fullName}</h2></div>
                            <div><h2>{each.hamal_charkhi}</h2></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </Fragment>
     );
}
 
export default Charkhi;